import request from '../utils/request';


//首页统计信息
export const findIndexCountAPI = query => {
    return request({
        url: '/findStatistics',
        method: 'post',
        data: query
    });
}

//订单统计
export const findOrderCountAPI = query => {
    return request({
        url: '/findOrderStatistics',
        method: 'get',
        param: query
    });
}

//提现统计
export const findWithdrawCountAPI = query => {
    return request({
        url: '/findSettleStatistics',
        method: 'get',
        param: query
    });
}

//业绩排行榜
export const findAchievementAPI = query => {
    return request({
        url: '/findRanking',
        method: 'get',
        param: query
    });
}

//已售商品分布
export const findGoodsCountAPI = query => {
    return request({
        url: '/findGoodsDistribution',
        method: 'get',
        param: query
    });
}


