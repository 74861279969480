<template>

    <div id="data-view">
      <dv-full-screen-container>
    <dv-border-box-11 title="矩牛数据驾驶舱">
<!--      <dv-loading>Loading...</dv-loading>-->

    </dv-border-box-11>

      </dv-full-screen-container>
    </div>

</template>

<script>
export default {
  name: 'DataShow',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
