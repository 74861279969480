<template>
  <div id="water-level-chart">
    <div class="water-level-chart-title">链动2+1分佣金额</div>

    <div class="water-level-chart-details">
      已提现
      <countTo :startVal='startVal' :endVal='settleAmount' :duration='2000'></countTo>
      元
    </div>
    <div class="water-level-chart-details">
      未提现
      <countTo :startVal='startVal' :endVal='balanceAmount' :duration='1000'></countTo>
      元
    </div>
    <div class="chart-container">
      <dv-water-level-pond :config="config"/>
    </div>
  </div>
</template>

<script>
import {findWithdrawCountAPI} from "@/api";
import countTo from 'vue-count-to';

export default {
  name: 'WaterLevelChart',
  components: {
    countTo
  },
  data() {
    return {
      startVal: 0,
      settleAmount: 0,
      balanceAmount: 0,
      config: {}
    }
  },
  methods: {
    getData() {
      let that = this
      findWithdrawCountAPI().then(res => {
        that.settleAmount = res.data.totalSettleAmount
        that.balanceAmount =res.data.totalBalanceAmount
        that.config = {
          data: [res.data.totalSettleAmountRatio], //水池数据
          shape: 'round',//水池形状
          waveHeight: 25,//波浪高度
          waveNum: 2 //波浪数量
        }
      })
    },
  },
  mounted() {
    this.getData()
  }

}
</script>

<style lang="less">
#water-level-chart {
  width: 20%;
  box-sizing: border-box;
  margin-left: 20px;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  display: flex;
  flex-direction: column;

  .water-level-chart-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
  }

  .water-level-chart-details {
    height: 15%;
    display: flex;
    justify-content: center;
    font-size: 17px;
    align-items: flex-end;

    span {
      font-size: 35px;
      font-weight: bold;
      color: #58a1ff;
      margin: 0 5px;
      margin-bottom: -5px;
    }
  }

  .chart-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dv-water-pond-level {
    max-width: 90%;
    width: 200px;
    height: 200px;
    border: 10px solid #19c3eb;
    border-radius: 50%;

    ellipse {
      stroke: transparent !important;
    }

    text {
      font-size: 40px;
    }
  }
}
</style>
