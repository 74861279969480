import axios from 'axios';



const service = axios.create({
    // baseURL:  'http://localhost:8999',
    baseURL: 'https://api.nanguiren.cn/statistics2',
    // baseURL: 'https://baodan.nanguiren.cn',
    timeout: 50000,
});

service.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
			const that = this
            	return response.data;

        } else {
            Promise.reject();
        }
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

export default service;
