<template>
  <div id="scroll-board">
    <dv-scroll-board :config="config" />
  </div>
</template>

<script>

import {findOrderCountAPI} from "@/api";

export default {
  name: 'ScrollBoard',
  data () {
    return {
      config: {

      }
    }
  },
  methods: {
  getData(){
    let that = this
    findOrderCountAPI().then(res => {

      that.config = {
        header: ['下单时间', '商品信息', '数量','订单金额', '下单用户'],
        data: res.data,
        index: true,
        columnWidth: [50, 180, 180,100],
        align: ['center'],
        rowNum: 7, // 行数
        headerBGC: '#1981f6',
        headerHeight: 45,
        oddRowBGC: 'rgba(0, 44, 81, 0.8)',
        evenRowBGC: 'rgba(10, 29, 50, 0.8)'
      }

    })

  },
  },

  mounted() {
    const { getData } = this
    getData()
    setInterval(getData, 30000) //


  }
}
</script>

<style lang="less">
#scroll-board {
  width: 50%;
  box-sizing: border-box;
  margin-left: 20px;
  height: 100%;
  overflow: hidden;
}
</style>
