import Vue from 'vue'
import VueRouter from 'vue-router'
import dataShow from '../components/datav/index.vue'
import dataShow2 from '../components/DataShow.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: dataShow
    },
    // {
    //     path: '/dataShow',
    //     name: 'dataShow',
    //     component: dataShow2
    // }

]

const router = new VueRouter({
    routes
})

export default router
